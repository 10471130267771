

  @import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap');

:root {
    --main-color: #1c1c1c;
    --bg-section-color : #F7F7F7;
    --paragraph-color: #748182;
    --gray-color: #eee;
    --white-color: #ffffff;
    --form-color: #f4f4f4;
    --main-transition: 0.4s;
}
/* start global rules */
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    font-family: 'Almarai', sans-serif;
}
html {
    scroll-behavior: smooth;
}
body {
    font-family: 'Almarai', sans-serif;

}
h1,h2,h3,h4,h5,h6 {
    font-family: 'Almarai', sans-serif;
}
ul {
    list-style: none;
}
a {
    text-decoration: none;
}
.section-padding {
    padding: 4rem 0;
}
.bg-section {
    background-color: var(--bg-section-color);
}
.paragraph {
    color: var(--paragraph-color);
    line-height: 1.8;
}
.nav-item , .links {
    cursor: pointer;
}
.rtl-direction {
    direction: rtl;
}
/* end global rules */
/* start scrolling */
.navbar {
    background-color: #9fc0e1;
    transition: background-color var(--main-transition) ease;
}  
.navbar.scrolled {
    background-color: #7290af;
}
.dropdown-menu[data-bs-popper] {
    top: 48px;
    left: -125px;
}
.main-slider .slick-list {
    top: 75px !important;
}
@media all and (min-width: 778px){
    .main-slider,
    .main-slider .gallery,
    .main-slider .image {
        height: 75vh !important;
    }
}
@media all and (max-width: 992px){
    .dropdown-menu[data-bs-popper] {
        top: 37px;
        left: -125px;
    }
}
.dropdown-toggle::after {
    display: none;
}
@media all and (min-width: 991px){
    .dropWeb {
        display: none !important;
    }
}
@media all and (max-width: 992px){
    .dropMobile {
        display: none !important;
    }
}
/* end scrolling */
.services .item .image ,
.contact .image{
    width: 85px;
    height: 85px;
    background-color: var(--bg-section-color);
    margin: 20px auto;
    transition: var(--main-transition);
}
.contact .icon {
    width: 70px;
    height: 70px;
}
.services .item:hover .icon,
.contact .item:hover .icon {
    color: var(--white-color);
    background-color: var(--main-color);
}
.form-control:focus {
    background-color: var(--form-color);
    box-shadow: 0 0 0 0.25rem rgb(1 45 109 / 25%);;
}
/* start Faqs */
.accordion {
    --bs-accordion-active-bg: #8396b3;
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgb(85 118 166 / 25%);
}
.accordion-header button {
    position: relative;
}
.arabic .accordion-button::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition);
}

.arabic .accordion-button::after {
    margin-left: auto;
}

.arabic .accordion-button::after {
    margin-left: 0;
    margin-right: auto;
}
/* end Faqs */
 