.sectionTitle {
    width: fit-content;
    margin: 0 auto 60px;
    position: relative;
}
.sectionTitle h2{
    font-size: 50px;
    position: relative;
    z-index: 10;
    margin-top: 12px;
}
.sectionTitle::after{
    content: '';
    position: absolute;
    top: 65%;
    left: 0%;
    width: 100%;
    height: 50%;
    z-index: 5;
    background-image: url(../../Assets/Images/dots.png);
}