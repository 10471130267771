.mainContainer{
    margin: 10px;
  }
  
  .mainContainer .container{
    padding: 20px 0;

  }
  
  .mainContainer .container img{
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }